import React from 'react';

const FinishCompose = (
    props: {
        image_url: string,
        paint: {
            id: string
            name: string,
            config: Config,
        },
        setFinish: React.Dispatch<React.SetStateAction<{
            image_url: string;
        } | null>>
    }
) => {
    const { image_url, setFinish, paint } = props;
    return (
        <div className='w-full h-full absolute top-0 left-0 bg-white/80 z-30 flex justify-center items-center flex-col'>
            <div className="top-0 right-0 absolute w-14 h-14 cursor-pointer" onClick={() => setFinish(null)}>
                <svg fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
            </div>
            <h2 className='text-3xl h-14'>
                Congratulations. You are done.
            </h2>
            <div className="w-full h-1/2 sm:w-3/4 sm:h-3/4 flex items-center justify-center bg-white/90 shadow-2xl flex-col">

                <div className="w-1/2 flex justify-center items-center ">
                    <img className='shadow-md' src={image_url} alt={`compose-${paint.id}`} width={"500px"} />
                </div>
                <div className="w-1/2 flex justify-center items-center ">
                    {
                        paint.config.interatif.gallery.use ? <a className="text-center flex justify-center items-center text-2xl" href={`/gallery/${paint.id}/compose`} rel="noreferrer" target="_blank">
                            {paint.config.interatif.gallery.text.replace(/{{paint}}/, paint.name)}
                        </a> : null
                    }
                </div>
            </div>
        </div>
    );
};

export default FinishCompose;