import { useState } from "react";
import { fonts } from "../../utils/font";
import { ImageList } from "./imageList";

const ConfigElement = (props: { config: Config; setConfig: (config: Config) => void }) => {
    // const { setSubmit, handleConfigChangle } = props;
    const { config, setConfig } = props;

    // const [config, setConfig] = useState(props.config);
    // const [showAllImage, setShowAllImage] = useState(config.image_paths.length < 3);
    const [showTools, setShowTools] = useState(false);
    // const scrollInToElement: React.LegacyRef<HTMLDivElement> = (element) => {
    //     element?.scrollIntoView({ behavior: "smooth", block: "end" });
    // };

    // const configChange = () => {
    //     handleConfigChangle(config);
    //     setSubmit(true);
    // };
    return (
        <>
            <div className="bg-white dark:bg-gray-900 overflow-x-hidden">
                {/*  colors palette */}
                <div className="bg-white dark:bg-gray-900 ml-3 mt-2">
                    <h3 className="text-sm font-medium text-gray-400 mb-3 underline">colors palette</h3>
                    <div className="flex justify-center items-center flex-col gap-2 w-full">
                        {config.colors_palette.map((color, index) => {
                            return (
                                <div className="flex justify-start items-center w-full" key={index}>
                                    <div className="w-1/3 overflow-hidden rounded-xl">
                                        <input
                                            className="bg-transparent rounded-none w-full h-10 p-0 border-0"
                                            type="color"
                                            value={color}
                                            onChange={(event) => {
                                                const localconfig = { ...config };
                                                localconfig.colors_palette[index] = event.target.value;
                                                setConfig(localconfig);
                                            }}
                                        />
                                    </div>

                                    <div className="w-1/4 h-10 flex justify-center items-center cursor-pointer rounded-lg ml-3">
                                        <input
                                            type="text"
                                            value={color}
                                            onChange={(event) => {
                                                const localconfig = { ...config };
                                                localconfig.colors_palette[index] = event.target.value;
                                                setConfig(localconfig);
                                            }}
                                            className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg w-full h-10  px-2"
                                        />
                                    </div>
                                    <div
                                        className={`text-center w-1/3 h-10 flex justify-center items-center  cursor-pointer rounded-lg ml-3 ${config.default_colors_select === index ? "bg-blue-700/60" : "hover:bg-blue-600 bg-blue-700"
                                            }`}
                                        onClick={(event) => {
                                            const localconfig = { ...config };
                                            localconfig.default_colors_select = index;
                                            setConfig(localconfig);
                                        }}
                                    >
                                        select as default
                                    </div>
                                    <div
                                        className="w-14 h-10 flex justify-center items-center bg-red-600 cursor-pointer rounded-lg ml-3 hover:bg-red-700"
                                        onClick={() => {
                                            const localconfig = { ...config };
                                            const colors_palette = localconfig.colors_palette;
                                            let default_colors_select = localconfig.default_colors_select;
                                            const currentColorSelect = colors_palette[default_colors_select + 1];
                                            if (!currentColorSelect) localconfig.default_colors_select = 0;
                                            localconfig.colors_palette.splice(index, 1);

                                            localconfig.default_colors_select = index;
                                            setConfig(localconfig);

                                            // setConfig((config) => {
                                            //     const colors_palette = config.colors_palette;
                                            //     let default_colors_select = config.default_colors_select;
                                            //     const currentColorSelect = colors_palette[default_colors_select + 1];
                                            //     if (!currentColorSelect) config.default_colors_select = 0;
                                            //     colors_palette.splice(index, 1);
                                            //     return config;
                                            // });
                                            // configChange();
                                        }}
                                    >
                                        <div className="w-full flex justify-center items-center">
                                            <svg className="w-6 h-6" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                ></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="w-full mt-3 flex justify-end">
                        <div
                            className="bg-blue-700 w-10 h-10 rounded-lg cursor-pointer"
                            onClick={() => {
                                const localconfig = { ...config };
                                localconfig.colors_palette.push("#FFFFFF");
                                setConfig(localconfig);
                            }}
                        >
                            <svg className="w-10 h-10" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                {/* tools */}
                <div className="bg-white dark:bg-gray-900 ml-3 mt-2 mb-2  overflow-x-hidden">
                    <h3 className="text-sm font-medium text-gray-400 mb-3 underline">tools</h3>
                    <div className="flex justify-center items-center flex-col gap-5 w-full">
                        {config.tools.map((tool, index) => {
                            if (tool.action === "bucket" && !config.use_image) return null;
                            if (index >= 1 && !showTools) return null;
                            return (
                                <div className="w-full  dark:bg-gray-800 rounded-lg h-auto py-2 " key={index}>
                                    <div className="w-full h-10 flex justify-center items-center mb-2">
                                        <div className="w-11/12 flex flex-row bg-gray-700 rounded-md h-10">
                                            <div className="w-1/2 h-full flex justify-center items-center">action</div>
                                            <div className="w-1/2 flex justify-center items-center">{tool.action}</div>
                                        </div>
                                    </div>
                                    {tool.action === "marker" || tool.action === "eraser" ? (
                                        <div className="w-full h-auto flex items-center justify-center mb-2">
                                            <div className="h-auto flex flex-row w-11/12 bg-gray-700 rounded-md">
                                                <div className="w-1/2 h-auto flex justify-center items-center text-sm text-gray-400">size</div>
                                                <div className="w-1/2 flex justify-center items-center h-auto px-5 gap-3">
                                                    <div className="h-20 flex flex-col items-center justify-center">
                                                        <div className="py-2">
                                                            <input
                                                                type="number"
                                                                value={tool.size}
                                                                onChange={(event) => {
                                                                    const localconfig = { ...config };
                                                                    const tools = localconfig.tools;
                                                                    const tool = tools[index];
                                                                    if ("size" in tool) tool.size = event.target.valueAsNumber;
                                                                    localconfig.tools = tools;
                                                                    setConfig(localconfig);

                                                                }}
                                                                className="text-2xl font-bold text-black dark:text-white dark:bg-gray-900 rounded-lg px-1 w-full appearance-none"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    <div className="w-full h-auto flex items-center justify-center mb-2">
                                        <div className="h-auto flex flex-row w-11/12 bg-gray-700 rounded-md">
                                            <div className="w-1/2 h-auto flex justify-center items-center text-sm text-gray-400 ">icon</div>
                                            <div className="w-1/2 flex justify-center items-center h-auto px-5 gap-3">
                                                <div className="h-40 flex flex-col items-center justify-center">
                                                    <div className="py-2">
                                                        <input
                                                            type="text"
                                                            value={tool.icon}
                                                            className="text-sm h-10 font-bold text-black dark:text-white dark:bg-gray-900 rounded-lg px-1 w-full appearance-none"
                                                            onChange={(event) => {
                                                                const localconfig = { ...config };
                                                                const tools = localconfig.tools;
                                                                const tool = tools[index];
                                                                if ("icon" in tool) tool.icon = event.target.value;
                                                                localconfig.tools = tools;
                                                                setConfig(localconfig);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="w-24 h-24 flex justify-center items-center bg-white rounded-full">
                                                        <img src={tool.icon} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full h-auto flex justify-center items-center">
                                        <div className="h-auto flex flex-row w-11/12 bg-gray-700 rounded-md">
                                            <div className="w-1/2 h-auto flex justify-center items-center text-sm text-gray-400 ">text</div>
                                            <div className="w-1/2 flex justify-center items-center h-auto px-5 gap-3">
                                                <div className="py-2">
                                                    <input
                                                        type="text"
                                                        value={tool.text}
                                                        className="text-sm h-10 font-bold text-black dark:text-white dark:bg-gray-900 rounded-lg px-1 w-full appearance-none"
                                                        onChange={(event) => {

                                                            const localconfig = { ...config };
                                                            const tools = localconfig.tools;
                                                            const tool = tools[index];
                                                            tool.text = event.target.value;
                                                            localconfig.tools = tools;
                                                            setConfig(localconfig);

                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {tool.action === "link" ? (
                                        <>
                                            <div className="w-full h-auto mt-2 flex justify-center items-center">
                                                <div className="h-auto flex flex-row  w-11/12 bg-gray-700 rounded-md">
                                                    <div className="w-1/2 h-auto flex justify-center items-center text-sm text-gray-400 ">link</div>
                                                    <div className="w-1/2 flex justify-center items-center h-auto px-5 gap-3">
                                                        <div className="py-2">
                                                            <input
                                                                type="text"
                                                                value={tool.href}
                                                                className="text-sm h-10 font-bold text-black dark:text-white dark:bg-gray-900 rounded-lg px-1 w-full appearance-none"
                                                                onChange={(event) => {
                                                                    const localconfig = { ...config };
                                                                    const tools = localconfig.tools;
                                                                    const tool = tools[index];
                                                                    if ("href" in tool) tool.href = event.target.value;
                                                                    localconfig.tools = tools;
                                                                    setConfig(localconfig);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : null}

                                    <div className="w-full h-auto mt-2 flex justify-center items-center mb-2">
                                        <div className="h-auto flex flex-row w-11/12 bg-gray-700 rounded-md">
                                            <label className="w-1/2 h-auto flex justify-center items-center text-sm text-gray-400 select-none" htmlFor={`show-checkbox-${index}`}>
                                                show
                                            </label>
                                            <div className="w-1/2 flex justify-center items-center h-auto px-5 gap-3">
                                                <div className="py-5 flex justify-center items-center">
                                                    <input
                                                        id={`show-checkbox-${index}`}
                                                        checked={tool.show}
                                                        value={""}
                                                        type="checkbox"
                                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                        onChange={(event) => {


                                                            const localconfig = { ...config };
                                                            const tools = localconfig.tools;
                                                            const tool = tools[index];
                                                            tool.show = event.target.checked;
                                                            localconfig.tools = tools;

                                                            setConfig(localconfig);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {tool.action === "marker" || tool.action === "eraser" || tool.action === "bucket" ? (
                                        <>
                                            <div className="w-full h-auto flex items-center justify-center">
                                                <div className="w-full h-auto flex items-center justify-center mb-2">
                                                    <div className="h-auto flex flex-row w-11/12 bg-gray-700 rounded-md">
                                                        <div className="w-1/2 h-auto flex justify-center items-center text-sm text-gray-400 ">cursor icon</div>
                                                        <div className="w-1/2 flex justify-center items-center h-auto px-5 gap-3">
                                                            <div className="h-40 flex flex-col items-center justify-center">
                                                                <div className="py-2">
                                                                    <input
                                                                        type="text"
                                                                        value={tool.iconPath}
                                                                        className="text-sm h-10 font-bold text-black dark:text-white dark:bg-gray-900 rounded-lg px-1 w-full appearance-none"
                                                                        onChange={(event) => {
                                                                            const localconfig = { ...config };
                                                                            const tools = localconfig.tools;
                                                                            const tool = tools[index];
                                                                            if ("iconPath" in tool) tool.iconPath = event.target.value;
                                                                            localconfig.tools = tools;
                                                                            setConfig(localconfig);
                                                                            // setConfig((config) => {
                                                                            //     const tools = config.tools;
                                                                            //     const tool = tools[index];
                                                                            //     if ("iconPath" in tool) tool.iconPath = event.target.value;
                                                                            //     return config;
                                                                            // });
                                                                            // configChange();
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="w-24 h-24 flex justify-center items-center bg-white">
                                                                    <img src={tool.iconPath} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-full h-auto flex justify-center mb-2">
                                                <div className="flex justify-center items-center w-11/12 h-20 bg-gray-700 rounded-md">
                                                    <button
                                                        className={` text-center w-6/12 h-10 flex justify-center items-center  cursor-pointer rounded-lg ${tool.active ? "bg-blue-600/50" : "hover:bg-blue-600 bg-blue-700"
                                                            }`}
                                                        onClick={() => {
                                                            const localconfig = { ...config };
                                                            const tools = localconfig.tools;
                                                            tools.forEach((tool) => "active" in tool && (tool.active = false));
                                                            const tool = tools[index];
                                                            if ("active" in tool) tool.active = true;
                                                            localconfig.tools = tools;
                                                            setConfig(localconfig);
                                                            // setConfig((config) => {
                                                            //     const tools = config.tools;
                                                            //     tools.forEach((tool) => ("active" in tool ? (tool.active = false) : null));
                                                            //     const tool = tools[index];
                                                            //     if ("active" in tool) tool.active = true;
                                                            //     return config;
                                                            // });
                                                            // configChange();
                                                        }}
                                                    >
                                                        active
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    ) : null}

                                    <div className="w-full flex justify-end px-5">
                                        <div
                                            className="w-14 h-10 flex justify-center items-center bg-red-600 cursor-pointer rounded-lg ml-3 hover:bg-red-700"
                                            onClick={() => {
                                                const localconfig = { ...config };
                                                const tools = localconfig.tools;
                                                tools.splice(index, 1);
                                                localconfig.tools = tools;
                                                setConfig(localconfig);

                                            }}
                                        >
                                            <div className="w-full flex justify-center items-center">
                                                <svg className="w-6 h-6" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                    ></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        <div className="w-full flex justify-end">
                            <div
                                className="bg-blue-700 w-10 h-10 rounded-lg cursor-pointer"
                                onClick={() => {
                                    // setConfig((config) => {
                                    //     const image_paths = config.image_paths;
                                    //     image_paths.push('./img/svg/default-icon.svg');
                                    //     if (image_paths.length > 3) {
                                    //         setShowAllImage(true);
                                    //     }
                                    //     configChange();
                                    //     return config;
                                    // })
                                }}
                            >
                                <svg className="w-10 h-10" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15"></path>
                                </svg>
                            </div>
                        </div>
                        <button
                            type="button"
                            onClick={() => {
                                setShowTools(!showTools);
                            }}
                            className="text-center w-1/3 h-10 flex justify-center items-center cursor-pointer rounded-lg ml-3 hover:bg-blue-600 bg-blue-700"
                        >
                            {showTools ? "hide tools" : `show tools +${config.tools.length - 1}`}
                        </button>
                    </div>
                </div>
                {/* canvas */}
                <div className="bg-white dark:bg-gray-900 ml-3 mt-2 mb-2">
                    <h3 className="text-sm font-medium text-gray-400 mb-3 underline">canvas</h3>
                    <div className="flex justify-center items-center flex-col gap-2 w-full mb-2">
                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full ">
                            <h3 className="text-sm font-medium text-gray-400 ml-2">scale raio</h3>
                            <div className="flex justify-center text-2xl gap-2  ml-6 mt-2">
                                <input
                                    type="number"
                                    className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                    step="0.1"
                                    max="3"
                                    min="1"
                                    value={config.canvas.scale_raio}
                                    onChange={(event) => {
                                        const localconfig = { ...config };
                                        localconfig.canvas.scale_raio = event.target.valueAsNumber;
                                        setConfig(localconfig);

                                    }}
                                />
                            </div>
                        </div>
                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full mb-2">
                            <h3 className="text-sm font-medium text-gray-400 ml-2">default size</h3>
                            <div className="flex justify-center text-2xl gap-2  ml-6 mt-2">
                                <h3 className="text-sm font-medium text-gray-400 flex justify-center items-center">width</h3>
                                <input
                                    type="number"
                                    className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                    step="100"
                                    max="1000"
                                    min="100"
                                    value={config.canvas.default_size.width}
                                    onChange={(event) => {

                                        const localconfig = { ...config };
                                        localconfig.canvas.default_size.width = event.target.valueAsNumber;
                                        setConfig(localconfig);
                                        // setConfig((config) => {
                                        //     const canvas = config.canvas;
                                        //     canvas.default_size.width = event.target.valueAsNumber;
                                        //     return config;
                                        // });
                                        // configChange();
                                    }}
                                />
                            </div>
                            <div className="flex justify-center text-2xl gap-2  ml-6 mt-2 ">
                                <h3 className="text-sm font-medium text-gray-400 flex justify-center items-center">height</h3>
                                <input
                                    type="number"
                                    className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                    step="100"
                                    max="1000"
                                    min="100"
                                    value={config.canvas.default_size.height}
                                    onChange={(event) => {
                                        const localconfig = { ...config };
                                        localconfig.canvas.default_size.height = event.target.valueAsNumber;
                                        setConfig(localconfig);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full mb-2">
                            <h3 className="text-sm font-medium text-gray-400 ml-2">min size</h3>
                            <div className="flex justify-center text-2xl gap-2  ml-6 mt-2">
                                <input
                                    type="number"
                                    className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                    step="100"
                                    max="1000"
                                    min="100"
                                    value={config.canvas.minsize}
                                    onChange={(event) => {
                                        const localconfig = { ...config };
                                        localconfig.canvas.minsize = event.target.valueAsNumber;
                                        setConfig(localconfig);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                            <h3 className="text-sm font-medium text-gray-400 ml-2">render size</h3>
                            <div className="flex justify-center text-2xl gap-2  ml-6 mt-2">
                                <h3 className="text-sm font-medium text-gray-400 flex justify-center items-center">width</h3>
                                <input
                                    type="number"
                                    className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                    step="100"
                                    max="10000"
                                    min="100"
                                    value={config.canvas.render.size.width}
                                    onChange={(event) => {

                                        const localconfig = { ...config };
                                        localconfig.canvas.render.size.width = event.target.valueAsNumber;
                                        setConfig(localconfig);

                                    }}
                                />
                            </div>
                            <div className="flex justify-center text-2xl gap-2  ml-6 mt-2">
                                <h3 className="text-sm font-medium text-gray-400 flex justify-center items-center">height</h3>
                                <input
                                    type="number"
                                    className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                    step="100"
                                    max="10000"
                                    min="100"
                                    value={config.canvas.render.size.height}
                                    onChange={(event) => {
                                        const localconfig = { ...config };
                                        localconfig.canvas.render.size.height = event.target.valueAsNumber;
                                        setConfig(localconfig);
                                        // setConfig((config) => {
                                        //     const canvas = config.canvas;
                                        //     canvas.render.size.height = event.target.valueAsNumber;
                                        //     return config;
                                        // });
                                        // configChange();
                                    }}
                                />
                            </div>
                        </div>
                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full mb-2">
                            <h3 className="text-sm font-medium text-gray-400 ml-2">background color</h3>
                            <div className="flex justify-center text-2xl gap-2  ml-6 mt-2">
                                <input
                                    type="color"
                                    className="bg-transparent rounded-none w-full h-10 p-0"
                                    value={config.canvas.color}
                                    onChange={(event) => {
                                        const localconfig = { ...config };
                                        localconfig.canvas.color = event.target.value;
                                        setConfig(localconfig);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* eraser */}
                <div className="bg-white dark:bg-gray-900 mt-2 mb-2 ml-3">
                    <h3 className="text-sm font-medium text-gray-400 mb-3 underline">eraser</h3>
                    <div className="flex justify-center items-center flex-col gap-2 w-full mb-2">
                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full ">
                            <h3 className="text-sm font-medium text-gray-400 ml-2">size</h3>
                            <div className="flex justify-center text-2xl gap-2  ml-6 mt-2">
                                <input
                                    type="number"
                                    className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                    step="10"
                                    max="100"
                                    min="10"
                                    value={config.eraser.size}
                                    onChange={(event) => {
                                        const localconfig = { ...config };
                                        localconfig.eraser.size = event.target.valueAsNumber;
                                        setConfig(localconfig);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full ">
                            <h3 className="text-sm font-medium text-gray-400 ml-2">color</h3>
                            <div className="flex justify-center text-2xl gap-2  ml-6 mt-2">
                                <input
                                    type="color"
                                    className="bg-transparent rounded-none w-full h-10 p-0"
                                    value={config.eraser.color}
                                    onChange={(event) => {
                                        const localconfig = { ...config };
                                        localconfig.eraser.color = event.target.value;
                                        setConfig(localconfig);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* use image */}
                {/* <div className="bg-white dark:bg-gray-900 mt-2 mb-2  ml-3">
                    <h3 className="text-sm font-medium text-gray-400 mb-3 underline">use image</h3>
                    <div className="flex items-center ml-4 sm:justify-start justify-center">
                        <input
                            type="checkbox"
                            id="use-max-images-in-each-gallery"
                            checked={config.use_image}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            onChange={(event) => {
                                setConfig((config) => {
                                    config.use_image = event.target.checked;
                                    return config;
                                });
                                configChange();
                            }}
                        />
                    </div>
                </div> */}
                {/* font */}
                <div className="bg-white dark:bg-gray-900 mt-2 mb-2  ml-3">
                    <h3 className="text-sm font-medium text-gray-400 mb-3 underline">font</h3>
                    <div className="flex items-center ml-4 sm:justify-start justify-center">
                        <input
                            type="text"
                            id="use-max-images-in-each-gallery"
                            value={config.font}
                            className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg w-full h-10  px-2"
                            onChange={(event) => {
                                const localconfig = { ...config };
                                localconfig.font = event.target.value;
                                setConfig(localconfig);
                            }}
                        />
                    </div>
                    <div className="grid grid-cols-2 content-evenly gap-6 place-items-center p-5 ">
                        {fonts.map((font, index) => (
                            <button
                                key={index}
                                type="button"
                                onClick={() => {
                                    const localconfig = { ...config };
                                    localconfig.font = font.uri;
                                    setConfig(localconfig);
                                }}
                                className=" text-center w-full h-10 flex justify-center items-center cursor-pointer rounded-lg ml-3 hover:bg-blue-600 bg-blue-700"
                            >
                                {font.name}
                            </button>
                        ))}
                    </div>
                </div>

                {/*  use multi image */}
                {/* <div className="bg-white dark:bg-gray-900 mt-2 mb-2  ml-3">
                    <h3 className="text-sm font-medium text-gray-400 mb-3 underline">use multi image</h3>
                    <div className="flex sm:justify-start justify-center items-center ml-4">
                        <input
                            type="checkbox"
                            value=""
                            id="use-max-images-in-each-gallery"
                            checked={!!config.use_multi_image}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            onChange={(event) => {
                                setConfig((config) => {
                                    config.use_multi_image = event.target.checked;
                                    configChange();
                                    return config;
                                });
                            }}
                        />
                    </div>
                </div> */}
                {/*  review */}
                <div className="bg-white dark:bg-gray-900 mt-2 mb-2  ml-3">
                    <h3 className="text-sm font-medium text-gray-400 mb-3 underline">review</h3>
                    <div className="flex sm:justify-start justify-center items-start ml-4 flex-col">
                        <div className="text-sm font-medium text-gray-400 mb-3">use</div>
                        <input
                            type="checkbox"
                            value=""
                            id="use-max-images-in-each-gallery"
                            checked={!!config.review.use}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            onChange={(event) => {
                                const localconfig = { ...config };
                                localconfig.review.use = event.target.checked;
                                setConfig(localconfig);
                            }}
                        />
                    </div>
                    {/* <div className={`flex sm:justify-start justify-center items-start ml-4 mt-2 flex-col ${config.review.use ? "" : "opacity-70"}`}>
                        <div className='text-sm font-medium text-gray-400 mb-1'>
                            notification
                        </div>
                        <div className="flex sm:justify-start justify-center items-start ml-4 flex-col">
                            <div className='text-sm font-medium text-gray-400 mb-3'>
                                use
                            </div>
                            <input
                                type="checkbox"
                                disabled={!config.review.use}
                                value=""
                                checked={!!config.review.notification.use}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                onChange={(event) => {
                                    setConfig((config) => {
                                        config.review.notification.use = event.target.checked;
                                        configChange();
                                        return config;
                                    });
                                }}
                            />
                        </div>
                        <div className="flex sm:justify-start justify-center items-start ml-4 flex-col">
                            <div className='text-sm font-medium text-gray-400 mb-3'>
                                email
                            </div>
                            <input
                                type="text"
                                disabled={!config.review.use}
                                value={config.review.notification.email}
                                className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg w-full h-10  px-2"
                                onChange={(event) => {
                                    setConfig((config) => {
                                        config.review.notification.email = event.target.value;
                                        configChange();
                                        return config;
                                    });
                                }}
                            />
                        </div>
                    </div> */}
                </div>
                {/*  get */}
                <div className="bg-white dark:bg-gray-900 mt-2 mb-2  ml-3">
                    <h3 className="text-sm font-medium text-gray-400 mb-3 underline">get</h3>
                    <div className="ml-5">
                        <h3 className="text-sm font-medium text-gray-400 mb-3 ">name</h3>
                        <div className="flex sm:justify-start justify-center items-center ml-4">
                            <input
                                type="checkbox"
                                value=""
                                id="use-max-images-in-each-gallery"
                                checked={!!config.get.name.use}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                onChange={(event) => {
                                    const localconfig = { ...config };
                                    localconfig.get.name.use = event.target.checked;
                                    setConfig(localconfig);
                                    // setConfig((config) => {
                                    //     config.get.name.use = event.target.checked;
                                    //     configChange();
                                    //     return config;
                                    // });
                                }}
                            />
                        </div>
                    </div>
                </div>
                {/* image paths */}
                {/* {config.use_image ? (
                    <div className="bg-white dark:bg-gray-900 ml-3 mt-2">
                        <h3 className="text-sm font-medium text-gray-400 mb-3 underline">image paths</h3>
                        <div className="flex justify-center items-center flex-col gap-2 w-full">
                            {config.image_paths.map((image_path, index) => {
                                if (!showAllImage && index > 2) return null;
                                return (
                                    <div
                                        className="flex justify-start items-center w-full m-5"
                                        key={index}
                                        ref={showAllImage && index === config.image_paths.length - 1 ? scrollInToElement : undefined}
                                    >
                                        <div className="w-1/3 h-40">
                                            <img src={`${globalConfig.base_uri.href}/${image_path}`} alt="error to found images" className="w-full h-full" />
                                        </div>
                                        <div className="w-1/4 h-10 flex justify-center items-center cursor-pointer rounded-lg ml-3">
                                            <input
                                                type="text"
                                                value={image_path}
                                                onChange={(event) => {
                                                    setConfig((config) => {
                                                        const image_paths = config.image_paths;
                                                        image_paths[index] = event.target.value;
                                                        configChange();
                                                        return config;
                                                    });
                                                }}
                                                className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg w-full h-10  px-2"
                                            />
                                        </div>
                                        <div
                                            className={`text-center w-1/3 h-10 flex justify-center items-center  cursor-pointer rounded-lg ml-3 ${
                                                config.default_image_select === index ? "bg-blue-700/60" : "hover:bg-blue-600 bg-blue-700"
                                            }`}
                                            onClick={(event) => {
                                                setConfig((config) => {
                                                    config.default_image_select = index;
                                                    configChange();
                                                    return config;
                                                });
                                            }}
                                        >
                                            select as default
                                        </div>
                                        <div
                                            className="w-14 h-10 flex justify-center items-center bg-red-600 cursor-pointer rounded-lg ml-3 hover:bg-red-700"
                                            onClick={() => {
                                                setConfig((config) => {
                                                    const image_paths = config.image_paths;
                                                    image_paths.splice(index, 1);
                                                    configChange();
                                                    return config;
                                                });
                                            }}
                                        >
                                            <div className="w-full flex justify-center items-center">
                                                <svg className="w-6 h-6" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                    ></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            {config.image_paths.length > 3 ? (
                                <div className="w-full mt-5 flex justify-center items-center">
                                    <div
                                        className="text-center w-1/3 h-10 flex justify-center items-center  cursor-pointer rounded-lg ml-3 hover:bg-blue-600 bg-blue-700"
                                        onClick={(event) => {
                                            setShowAllImage(!showAllImage);
                                        }}
                                    >
                                        {showAllImage ? "show less images" : `show more images +${config.image_paths.length - 3}`}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="w-full mt-3 flex justify-end">
                            <div
                                className="bg-blue-700 w-10 h-10 rounded-lg cursor-pointer"
                                onClick={() => {
                                    setConfig((config) => {
                                        const image_paths = config.image_paths;
                                        image_paths.push("./img/svg/default-icon.svg");
                                        if (image_paths.length > 3) {
                                            setShowAllImage(true);
                                        }
                                        configChange();
                                        return config;
                                    });
                                }}
                            >
                                <svg className="w-10 h-10" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                ) : null} */}
                {/* images */}
                <div className="mt-2 text-gray-400">
                    <h3 className="text-sm font-medium mb-3 underline">images</h3>
                    <div className="flex justify-center items-center flex-col gap-2 w-full pl-3">
                        <div className="w-full">
                            <label className="relative inline-flex items-center mb-5 cursor-pointer" htmlFor="paint-config-use-image">
                                <input
                                    type="checkbox"
                                    className="sr-only peer"
                                    id="paint-config-use-image"
                                    value=""
                                    checked={config.use_image}
                                    onChange={(event) => {
                                        const localconfig = { ...config };
                                        localconfig.use_image = event.target.checked;
                                        setConfig(localconfig);
                                    }}
                                />
                                <div className="w-11 h-6 peer-focus:outline-none peer-focus:ring-4 p rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-blue-600"></div>
                                <span className="ml-3 text-sm font-medium ">use</span>
                            </label>
                        </div>
                        <div className="w-full">
                            <label className="relative inline-flex items-center mb-5 cursor-pointer" htmlFor="paint-config-use-multi-image">
                                <input
                                    type="checkbox"
                                    className="sr-only peer"
                                    value=""
                                    id="paint-config-use-multi-image"
                                    checked={config.use_multi_image}
                                    onChange={(event) => {
                                        const localconfig = { ...config };
                                        localconfig.use_multi_image = event.target.checked;
                                        setConfig(localconfig);
                                    }}
                                />
                                <div className="w-11 h-6 peer-focus:outline-none peer-focus:ring-4 p rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-blue-600"></div>
                                <span className="ml-3 text-sm font-medium ">use multi</span>
                            </label>
                        </div>
                        <ImageList
                            config={config}
                            onChange={(config) => {
                                const localconfig = { ...config };
                                setConfig(localconfig);
                            }}
                        />
                    </div>
                </div>
                {/* svg filter colors */}
                <div className="bg-white dark:bg-gray-900 ml-3 mt-5 mb-2">
                    <h3 className="text-sm font-medium text-gray-400 underline">svg filter colors</h3>
                    <div className="bg-white dark:bg-gray-900 mt-2">
                        <div className="sm:flex sm:items-start">
                            <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                <div className="flex justify-center items-center flex-col gap-2 w-full">
                                    {config.svg_filter_colors.map((value, index) => {
                                        return (
                                            <div className="flex justify-center items-center w-full" key={index}>
                                                <input
                                                    className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg w-full h-10  px-2"
                                                    value={value}
                                                    onChange={(event) => {

                                                        const localconfig = { ...config };
                                                        const svg_filter_colors = localconfig.svg_filter_colors;
                                                        svg_filter_colors[index] = event.target.value;
                                                        localconfig.svg_filter_colors = svg_filter_colors;
                                                        setConfig(localconfig);
                                                    }}
                                                />
                                                <div
                                                    className="w-14 h-10 flex justify-center items-center bg-red-600 cursor-pointer rounded-lg ml-3 hover:bg-red-700"
                                                    onClick={() => {
                                                        const localconfig = { ...config };
                                                        const svg_filter_colors = localconfig.svg_filter_colors;

                                                        svg_filter_colors.splice(index, 1);

                                                        localconfig.svg_filter_colors = svg_filter_colors;
                                                        setConfig(localconfig);
                                                    }}
                                                >
                                                    <div className="w-full flex justify-center items-center">
                                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                            ></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="w-full mt-3 flex justify-end">
                                    <div
                                        className="bg-blue-700 w-10 h-10 rounded-lg"
                                        onClick={() => {
                                            const localconfig = { ...config };
                                            const svg_filter_colors = localconfig.svg_filter_colors;

                                            svg_filter_colors.push("#FFFFFF")
                                            localconfig.svg_filter_colors = svg_filter_colors;
                                            setConfig(localconfig);
                                        }}
                                    >
                                        <svg className="w-10 h-10" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15"></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* key input */}
                <div className="bg-white dark:bg-gray-900 ml-3 mt-5 mb-2">
                    <h3 className="text-sm font-medium text-gray-400 underline">key input</h3>
                    <div className="text-sm font-medium text-gray-400 mt-2 ml-2"> control + key</div>
                    <div className="flex justify-center items-center flex-col gap-2 w-full mb-2 mt-3">
                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                            <h3 className="text-sm font-medium text-gray-400 ml-2">undo</h3>
                            <div className="flex justify-center text-2xl gap-2  ml-6 mt-2">
                                <input
                                    type="number"
                                    className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                    value={config.key_input.undo}
                                    onKeyDown={(event) => {
                                        event.preventDefault();
                                        if (event.keyCode === 17) return;

                                        const localconfig = { ...config };

                                        localconfig.key_input.undo = event.keyCode;
                                        setConfig(localconfig);
                                    }}
                                    onChange={(event) => {

                                        const localconfig = { ...config };
                                        localconfig.key_input.undo = event.target.value;
                                        setConfig(localconfig);

                                        // setConfig((config) => {
                                        //     const key_input = config.key_input;
                                        //     key_input.undo = event.target.value;
                                        //     configChange();
                                        //     return config;
                                        // });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                            <h3 className="text-sm font-medium text-gray-400 ml-2">del</h3>
                            <div className="flex justify-center text-2xl gap-2  ml-6 mt-2">
                                <input
                                    type="number"
                                    className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                    value={config.key_input.del}
                                    onKeyDown={(event) => {
                                        event.preventDefault();
                                        if (event.keyCode === 17) return;

                                        const localconfig = { ...config };
                                        localconfig.key_input.del = event.keyCode;
                                        setConfig(localconfig);

                                    }}
                                    onChange={(event) => {
                                        const localconfig = { ...config };
                                        localconfig.key_input.del = event.target.value;
                                        setConfig(localconfig);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* intro */}
                <div className="bg-white dark:bg-gray-900 ml-3 mt-5 mb-2">
                    <h3 className="text-sm font-medium text-gray-400 underline">intro</h3>
                    <div className="flex justify-center items-center flex-col gap-2 w-full mb-2 mt-3">
                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                            <h3 className="text-sm font-medium text-gray-400 ml-2">show</h3>
                            <div className="flex justify-start items-center ml-5 mt-2">
                                <input
                                    type="checkbox"
                                    value=""
                                    id="use-max-images-in-each-gallery"
                                    checked={config.intro.state}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    onChange={(event) => {
                                        const localconfig = { ...config };
                                        localconfig.intro.state = event.target.checked;
                                        setConfig(localconfig);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                            <h3 className="text-sm font-medium text-gray-400 ml-2">animation step top</h3>
                            <div className="flex justify-center text-2xl gap-2  ml-6 mt-2">
                                <input
                                    type="number"
                                    min="1"
                                    step="1"
                                    className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                    value={config.intro.animation_step_top}
                                    onChange={(event) => {
                                        const localconfig = { ...config };
                                        localconfig.intro.animation_step_top = event.target.valueAsNumber;
                                        setConfig(localconfig);
                                        // setConfig((config) => {
                                        //     config.intro.animation_step_top = event.target.valueAsNumber;
                                        //     configChange();
                                        //     return config;
                                        // });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                            <h3 className="text-sm font-medium text-gray-400 ml-2">animation step opacity x1000</h3>
                            <div className="flex justify-center text-2xl gap-2  ml-6 mt-2">
                                <input
                                    type="number"
                                    min="0.1"
                                    step="0.1"
                                    className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                    value={config.intro.animation_step_opacity * 1000}
                                    onChange={(event) => {

                                        const localconfig = { ...config };
                                        localconfig.intro.animation_step_opacity = event.target.valueAsNumber / 1000;
                                        setConfig(localconfig);
                                        // setConfig((config) => {
                                        //     config.intro.animation_step_opacity = event.target.valueAsNumber / 1000;
                                        //     configChange();
                                        //     return config;
                                        // });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                            <h3 className="text-sm font-medium text-gray-400 ml-2">text</h3>
                            <div className="flex justify-center text-2xl gap-2  ml-6 mt-2">
                                <input
                                    type="text"
                                    className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                    value={config.intro.text}
                                    onChange={(event) => {
                                        const localconfig = { ...config };
                                        localconfig.intro.text = event.target.value;
                                        setConfig(localconfig);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* credit */}
                {/* <div className="bg-white dark:bg-gray-900 ml-3 mt-5 mb-2">
                    <h3 className="text-sm font-medium text-gray-400 underline">
                        credit
                    </h3>
                    <div className="flex justify-center items-center flex-col gap-2 w-full mb-2 mt-3">
                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                            <h3 className="text-sm font-medium text-gray-400 ml-2">show</h3>
                            <div className="flex justify-start items-center ml-5 mt-2">
                                <input
                                    type="checkbox"
                                    id="use-max-images-in-each-gallery"
                                    checked={config.intro.state}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    onChange={(event) => {
                                        const intro = config.intro;
                                        intro.state = event.target.checked;
                                        setConfig({ ...config, intro });
                                        configChange();
                                    }}
                                />
                            </div>
                        </div>
                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                            <h3 className="text-sm font-medium text-gray-400 ml-2">image</h3>
                            <div className="flex justify-center text-2xl gap-2  ml-6 mt-2">
                                <input
                                    type="text"
                                    className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                    value={config.credit.image}
                                    onChange={(event) => {
                                        const credit = config.credit;
                                        credit.image = event.target.value;
                                        setConfig({ ...config, credit });
                                        configChange()

                                    }}
                                />
                            </div>
                        </div>
                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                            <h3 className="text-sm font-medium text-gray-400 ml-2">opacity</h3>
                            <div className="flex justify-center text-2xl gap-2  ml-6 mt-2">
                                <input
                                    type="number"
                                    min="0"
                                    max="1"
                                    step="0.1"
                                    className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                    value={config.credit.opacity}
                                    onChange={(event) => {

                                        const credit = config.credit;
                                        credit.opacity = event.target.valueAsNumber;
                                        setConfig({ ...config, credit });
                                        configChange()

                                    }}
                                />
                            </div>
                        </div>
                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                            <h3 className="text-sm font-medium text-gray-400 ml-2">position</h3>
                            <div className="flex justify-center text-2xl gap-2  ml-6 mt-2">
                                <div className="w-full">
                                    <input
                                        type="text"
                                        className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                        value={config.intro.text}
                                        onChange={(event) => {
                                            const intro = config.intro;
                                            intro.text = event.target.value;
                                            setConfig({ ...config, intro });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* default gallery name */}
                <div className="bg-white dark:bg-gray-900 ml-3 mt-5 mb-2">
                    <h3 className="text-sm font-medium text-gray-400 underline">default gallery name</h3>
                    <div className="text-sm font-medium text-gray-400 mt-2 ml-2">without question</div>
                    <div className="flex justify-center items-center flex-col gap-2 w-full mb-2 mt-3">
                        <div className="flex justify-center items-center w-full">
                            <input
                                type="text"
                                disabled
                                className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg w-full h-10  px-2"
                                value={config.default_gallery_name}
                                onChange={(event) => {
                                    const localconfig = { ...config };
                                    localconfig.default_gallery_name = event.target.value;
                                    setConfig(localconfig);
                                }}
                            />
                        </div>
                    </div>
                </div>
                {/*  message time out */}
                <div className="bg-white dark:bg-gray-900 ml-3 mt-5 mb-2">
                    <h3 className="text-sm font-medium text-gray-400 underline">message time out</h3>
                    <div className="text-sm font-medium text-gray-400 mt-2 ml-2">time to show message in second</div>
                    <div className="flex justify-center items-center flex-col gap-2 w-full mb-2 mt-3">
                        <div className="flex justify-center items-center w-full">
                            <input
                                type="number"
                                className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg w-full h-10  px-2"
                                value={config.message_time_out / 1000}
                                onChange={(event) => {
                                    const localconfig = { ...config };
                                    localconfig.message_time_out = event.target.valueAsNumber * 1000;
                                    setConfig(localconfig);
                                }}
                            />
                        </div>
                    </div>
                </div>
                {/* title */}
                <div className="bg-white dark:bg-gray-900 ml-3 mt-5 mb-2">
                    <h3 className="text-sm font-medium text-gray-400 underline">title</h3>
                    <div className="text-sm font-medium text-gray-400 mt-2 ml-2">title show in page "{"{{name}}"}" ref to name (paint name,gallery name)</div>
                    <div className="flex justify-center items-center flex-col gap-2 w-full mb-2 mt-3">
                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                            <h3 className="text-sm font-medium text-gray-400 ml-2">home</h3>
                            <div className="flex justify-center text-2xl gap-2  ml-6 mt-2">
                                <input
                                    type="text"
                                    className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                    value={config.title.home}
                                    onChange={(event) => {
                                        const localconfig = { ...config };
                                        localconfig.title.home = event.target.value;
                                        setConfig(localconfig);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                            <h3 className="text-sm font-medium text-gray-400 ml-2">gallery</h3>
                            <div className="flex justify-center text-2xl gap-2  ml-6 mt-2">
                                <input
                                    type="text"
                                    className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                    value={config.title.gallery}
                                    onChange={(event) => {

                                        const localconfig = { ...config };
                                        localconfig.title.gallery = event.target.value;
                                        setConfig(localconfig);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/*  live draw */}
                <div className="bg-white dark:bg-gray-900 ml-3 mt-5 mb-2">
                    <h3 className="text-sm font-medium text-gray-400 underline">live draw</h3>
                    <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                        <h3 className="text-sm font-medium text-gray-400">state</h3>
                        <div className="flex items-center sm:ml-5 mt-2 sm:justify-start justify-center">
                            <input
                                type="checkbox"
                                value=""
                                checked={config.live_draw.state}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                onChange={(event) => {

                                    const localconfig = { ...config };
                                    localconfig.live_draw.state = event.target.checked;
                                    setConfig(localconfig);
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex justify-center items-center flex-col gap-2 w-full mb-2 mt-3">
                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                            <h3 className="text-sm font-medium text-gray-400 ml-2">max users</h3>
                            <div className="flex justify-center text-2xl gap-2  ml-6 mt-2">
                                <input
                                    type="number"
                                    className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                    value={config.live_draw.max_users}
                                    onChange={(event) => {

                                        const localconfig = { ...config };
                                        localconfig.live_draw.max_users = event.target.valueAsNumber;
                                        setConfig(localconfig);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* interatif */}
                <div className="bg-white dark:bg-gray-900 ml-3 mt-5 mb-2">
                    <h3 className="text-sm font-medium text-gray-400 underline">interatif</h3>

                    <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                        <h3 className="text-sm font-medium text-gray-400">state</h3>
                        <div className="flex  items-center sm:ml-5 mt-2 sm:justify-start justify-center ">
                            <input
                                type="checkbox"
                                value=""
                                checked={config.interatif.state}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                onChange={(event) => {
                                    const localconfig = { ...config };
                                    localconfig.interatif.state = event.target.checked;
                                    setConfig(localconfig);
                                }}
                            />
                        </div>
                    </div>
                    <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full mb-2 ">
                        <h3 className="text-sm font-medium text-gray-400 mt-3">default size</h3>
                        <div className="flex justify-center text-2xl gap-2  ml-6 mt-2">
                            <h3 className="text-sm font-medium text-gray-400 flex justify-center items-center">width</h3>
                            <input
                                type="number"
                                className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                step="100"
                                max="1000"
                                min="100"
                                value={config.interatif.default_size.width}
                                onChange={(event) => {

                                    const localconfig = { ...config };
                                    localconfig.interatif.default_size.width = event.target.valueAsNumber;
                                    setConfig(localconfig);
                                }}
                            />
                        </div>
                        <div className="flex justify-center text-2xl gap-2  ml-6 mt-2 ">
                            <h3 className="text-sm font-medium text-gray-400 flex justify-center items-center">height</h3>
                            <input
                                type="number"
                                className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                step="100"
                                max="1000"
                                min="100"
                                value={config.interatif.default_size.height}
                                onChange={(event) => {

                                    const localconfig = { ...config };
                                    localconfig.interatif.default_size.height = event.target.valueAsNumber;
                                    setConfig(localconfig);
                                }}
                            />
                        </div>
                    </div>
                    <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full mb-2 ">
                        <h3 className="text-sm font-medium text-gray-400 mt-3">gallery</h3>
                        <div className="flex justify-start text-2xl gap-2  ml-6 mt-2">
                            <h3 className="text-sm font-medium text-gray-400 flex justify-center items-center">use</h3>
                            <div className="flex items-center sm:ml-2 mt-0 sm:justify-start justify-center">
                                <input
                                    type="checkbox"
                                    value=""
                                    checked={config.interatif.gallery.use}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    onChange={(event) => {

                                        const localconfig = { ...config };
                                        localconfig.interatif.gallery.use = event.target.checked;
                                        setConfig(localconfig);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex justify-start text-2xl gap-2  ml-6 mt-2 flex-col">
                            <h3 className="text-sm font-medium text-gray-400 flex justify-start items-center  ml-3 mt-2">image_line</h3>
                            <div className="flex justify-start text-2xl gap-2 ml-8 flex-col">
                                <h3 className="text-sm font-medium text-gray-400 flex justify-start items-center">use</h3>
                                <div className="flex items-center sm:ml-5 sm:justify-start justify-center">
                                    <input
                                        type="checkbox"
                                        value=""
                                        checked={config.interatif.gallery.image_line.state}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        onChange={(event) => {
                                            const localconfig = { ...config };
                                            localconfig.interatif.gallery.image_line.state = event.target.checked;
                                            setConfig(localconfig);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex justify-start text-2xl gap-2  ml-8 mt-0 flex-col ">
                                <h3 className="text-sm font-medium text-gray-400 flex justify-start items-center">count</h3>
                                <input
                                    type="number"
                                    className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg ml-2 px-1"
                                    value={config.interatif.gallery.image_line.count}
                                    onChange={(event) => {
                                        const localconfig = { ...config };
                                        localconfig.interatif.gallery.image_line.state = event.target.checked;
                                        setConfig(localconfig);
                                        // setConfig((config) => {
                                        //     const interatif = config.interatif;
                                        //     interatif.gallery.image_line.count = event.target.valueAsNumber;
                                        //     return config;
                                        // });
                                        // configChange();
                                    }}
                                />
                            </div>
                        </div>
                        {/* <div className="flex justify-start text-2xl gap-2  ml-6 mt-2 flex-col">
                            <h3 className="text-sm font-medium text-gray-400 flex justify-start items-center  ml-3 mt-2">show</h3>
                            <div className="flex justify-start text-2xl gap-2 ml-8 flex-col">
                                <h3 className="text-sm font-medium text-gray-400 flex justify-start items-center">name</h3>
                                <div className="flex items-center sm:ml-5 sm:justify-start justify-center">
                                    <input
                                        type="checkbox"
                                        value=""
                                        checked={config.interatif.gallery.show.name}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        onChange={(event) => {
                                            setConfig((config) => {
                                                const interatif = config.interatif;
                                                interatif.gallery.show.name = event.target.checked;
                                                configChange()
                                                return config;
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex justify-start text-2xl gap-2 ml-8 flex-col">
                                <h3 className="text-sm font-medium text-gray-400 flex justify-start items-center">date</h3>
                                <div className="flex items-center sm:ml-5 sm:justify-start justify-center">
                                    <input
                                        type="checkbox"
                                        value=""
                                        checked={config.interatif.gallery.show.date}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        onChange={(event) => {
                                            setConfig((config) => {
                                                const interatif = config.interatif;
                                                interatif.gallery.show.date = event.target.checked;
                                                configChange()
                                                return config;
                                            });
                                        }}
                                    />
                                </div>
                            </div>

                        </div> */}
                        <div className="flex justify-start text-2xl gap-2 ml-2 mt-0 flex-col ">
                            <h3 className="text-sm font-medium text-gray-400 flex justify-start items-center">text</h3>
                            <input
                                type="text"
                                className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg ml-2 px-1"
                                value={config.interatif.gallery.text}
                                onChange={(event) => {

                                    const localconfig = { ...config };
                                    localconfig.interatif.gallery.text = event.target.value;
                                    setConfig(localconfig);
                                }}
                            />
                        </div>
                    </div>
                    <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                        <h3 className="text-sm font-medium text-gray-400">row</h3>
                        <div className="flex items-center sm:ml-5 mt-2 sm:justify-start justify-center">
                            <input
                                type="checkbox"
                                value=""
                                checked={config.interatif.row}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                onChange={(event) => {
                                    const localconfig = { ...config };
                                    localconfig.interatif.row = event.target.checked;
                                    setConfig(localconfig);
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex justify-center items-center flex-col gap-2 w-full mb-2 mt-3">
                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                            <h3 className="text-sm font-medium text-gray-400">number of row</h3>
                            <div className="flex justify-center text-2xl gap-2  ml-6 mt-2">
                                <input
                                    type="number"
                                    className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                    value={config.interatif.default_row}
                                    onChange={(event) => {
                                        const localconfig = { ...config };
                                        localconfig.interatif.default_row = event.target.valueAsNumber;
                                        setConfig(localconfig);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* show home */}
                <div className="bg-white dark:bg-gray-900 ml-3 mt-5 mb-2">
                    <h3 className="text-sm font-medium text-gray-400 underline">show home</h3>
                    <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                        <div className="flex items-center sm:ml-2 mt-2 sm:justify-start justify-center">
                            <input
                                type="checkbox"
                                id="show_home"
                                value=""
                                checked={config.show_home}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                onChange={(event) => {
                                    const localconfig = { ...config };
                                    localconfig.show_home = event.target.checked;
                                    setConfig(localconfig);
                                }}
                            />
                        </div>
                    </div>
                </div>
                {/* gallery */}
                <div className="bg-white dark:bg-gray-900 ml-3 mt-5 mb-2">
                    <h3 className="text-sm font-medium text-gray-400 underline">gallery</h3>
                    <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                        <h3 className="text-sm font-medium text-gray-400 mt-2">live update</h3>
                        <div className="flex items-center sm:ml-5 mt-2 sm:justify-start justify-center">
                            <input
                                type="checkbox"
                                value=""
                                checked={config.gallery.live_update}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                onChange={(event) => {

                                    const localconfig = { ...config };
                                    localconfig.gallery.live_update = event.target.checked;
                                    setConfig(localconfig);
                                }}
                            />
                        </div>
                    </div>

                    <div className="flex justify-center items-center flex-col gap-2 w-full mb-2 mt-3">
                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                            <h3 className="text-sm font-medium text-gray-400 ml-2 ">show</h3>
                            <div className="flex justify-center items-start text-2xl gap-2 sm:ml-6 mt-2 flex-col">
                                <h3 className="text-sm font-medium text-gray-400 sm:ml-2">name</h3>
                                <input
                                    type="checkbox"
                                    checked={config.gallery.show.name}
                                    value=""
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 sm:ml-5"
                                    onChange={(event) => {
                                        const localconfig = { ...config };
                                        localconfig.gallery.show.name = event.target.checked;
                                        setConfig(localconfig);
                                    }}
                                />
                            </div>
                            <div className="flex justify-start items-start text-2xl gap-2 sm:ml-6 mt-2 flex-col w-full">
                                <h3 className="text-sm font-medium text-gray-400 sm:ml-2 w-full">date</h3>
                                <input
                                    type="checkbox"
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 sm:ml-5"
                                    checked={config.gallery.show.date}
                                    value=""
                                    onChange={(event) => {
                                        const localconfig = { ...config };
                                        localconfig.gallery.show.date = event.target.checked;
                                        setConfig(localconfig);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full ">
                        <h3 className="text-sm font-medium text-gray-400 mt-2">
                            finished text
                            <div className="text-sm font-medium text-gray-400 mt-2 ml-2">
                                text show in finished page "{"{{paint}}"}" to replace paint by paint name and "{"{{label}}"}" by label name{" "}
                            </div>
                        </h3>
                        <div className="flex items-center sm:ml-5 mt-2 sm:justify-start justify-center">
                            <input
                                type="text"
                                value={config.gallery.text}
                                className="ml-5 font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-10/12"
                                onChange={(event) => {
                                    const localconfig = { ...config };
                                    localconfig.gallery.text = event.target.value;
                                    setConfig(localconfig);
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex justify-center items-center flex-col gap-2 w-full mb-2 mt-3">
                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                            <h3 className="text-sm font-medium text-gray-400 ml-2 ">image line</h3>
                            <div className="flex justify-center items-start text-2xl gap-2 sm:ml-6 mt-2 flex-col">
                                <h3 className="text-sm font-medium text-gray-400 sm:ml-2">count</h3>
                                <input
                                    type="number"
                                    className="ml-5 font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-10/12"
                                    value={config.gallery.image_line.count}
                                    onChange={(event) => {
                                        const localconfig = { ...config };
                                        localconfig.gallery.image_line.count = event.target.valueAsNumber;
                                        setConfig(localconfig);

                                    }}
                                />
                            </div>
                            <div className="flex justify-start items-start text-2xl gap-2 sm:ml-6 mt-2 flex-col w-full">
                                <h3 className="text-sm font-medium text-gray-400 sm:ml-2 w-full">state</h3>
                                <input
                                    type="checkbox"
                                    className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 sm:ml-5"
                                    checked={config.gallery.image_line.state}
                                    value=""
                                    onChange={(event) => {
                                        const localconfig = { ...config };
                                        localconfig.gallery.image_line.state = event.target.checked;
                                        setConfig(localconfig);

                                        // setConfig((config) => {
                                        //     const gallery = config.gallery;
                                        //     gallery.image_line.state = event.target.checked;
                                        //     configChange();
                                        //     return config;
                                        // });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};
export default ConfigElement;