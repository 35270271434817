import React from "react";

const Finish = (props: { image_url: string; paint: string; label: string; paintId: string; close: () => void; next_question_index?: number; config: Config }) => {
    const { paint, label, image_url, close, paintId, next_question_index, config } = props;
    return (
        <div className="w-full h-full absolute top-0 left-0 bg-white/80 z-30 flex justify-center items-center flex-col cursor-pointer">
            <div className="top-0 right-0 absolute w-14 h-14 cursor-pointer" onClick={() => close()}>
                <svg fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
            </div>
            <h2 className="text-3xl h-14">Finished!!</h2>
            <div className="w-full h-1/2 sm:w-3/4 sm:h-3/4 flex items-center justify-center bg-white/90 shadow-2xl flex-col">
                <div className="w-1/2 flex justify-center items-center ">
                    <img className="shadow-md" src={image_url} alt={`${paint},${label}`} width={"500px"} />
                </div>
                <div className="w-1/2 flex justify-center items-center ">
                    <div className="w-full h-20 flex justify-center items-center">
                        <a className="text-center flex justify-center items-center text-2xl" href={`/gallery/${paintId}`} rel="noreferrer" target="_blank">
                            {config.gallery.text.replace(/{{paint}}/, paint).replace(/{{label}}/, label)}
                        </a>
                    </div>
                    {next_question_index ? (
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded  right-9"
                            onClick={() => {
                                window.location.href = `${window.location.pathname}?qi=${next_question_index}`;
                            }}
                        >
                            next question
                        </button>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default Finish;
