import { LoaderFunction, redirect, useLoaderData } from 'react-router-dom';
import isLogin from '../../../utils/isLogin';
import NotFound from '../../NotFound';
import { NavLink } from 'react-router-dom';
import config from '../../../config';

const Paint = () => {
    const data = useLoaderData() as ({ paint: TPaint, user: User } | null);

    if (!data) return <NotFound />
    const { paint } = data;
    const currentTime = new Date().getTime();

    const rtf = new Intl.RelativeTimeFormat("en", {
        localeMatcher: "best fit",
        numeric: "always",
        style: "long",
    });
    return (
        <div>
            {paint ?
                <div className='h-screen w-screen dark:bg-gray-800 overflow-x-hidden bg-gray-300'>
                    <div className="absolute top-0 right-0 w-36 h-36 flex items-center justify-center">
                        <div className='flex items-center justify-center '>
                            <div className="m-5">
                                <button className="flex p-2.5 rounded-xl">
                                    <NavLink className="flex p-2.5 bg-gray-800 rounded-xl hover:bg-gray-900  transition-all duration-300 text-white" to="/dashboard" >
                                        <svg className="h-8 w-8" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                        </svg>
                                    </NavLink>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="h-auto w-full flex items-center justify-center dark:text-white pt-16">
                        <span className='font-bold text-3xl'>{paint.name}</span>
                    </div>
                    <div className="h-auto w-full flex items-center justify-center dark:text-white pt-16 flex-col ">
                        <div className='w-full flex justify-center items-center flex-col h-auto'>
                            <div className="inline-block align-bottom dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow transform transition-all w-full sm:w-1/2 ">
                                <div className="bg-gray-100 p-5 dark:bg-gray-900">
                                    <div className="sm:flex sm:items-start">
                                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                            <h3 className="text-sm leading-6 font-medium text-gray-400">status</h3>
                                            <p className="text-3xl font-bold text-black dark:text-white">{paint.statue}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={`$absolute left-0 bottom-0 w-full h-1 ${((paint.statue === "active") ? "bg-green-500" : (paint.statue === "require_payment" ? "bg-blue-500" : "bg-red-500"))}`}></div>
                            </div>
                            <div className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow transform transition-all w-full sm:w-1/2 sm:mt-3">
                                <div className="bg-gray-100 p-5 dark:bg-gray-900">
                                    <div className="sm:flex sm:items-start">
                                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                            <h3 className="text-sm leading-6 font-medium text-gray-400">gallery</h3>
                                            <span className='px-4 block'>
                                                <p className="text-1xl font-bold text-black dark:text-white">gallery count</p>
                                                <p className="text-1xl font-bold text-black dark:text-gray-400 px-3">{paint.gallery.storages.length}</p>
                                            </span>
                                            <span className='px-4 block'>
                                                <p className="text-1xl font-bold text-black dark:text-white">max image in one gallery</p>
                                                <p className="text-1xl font-bold text-black dark:text-gray-400 px-3">{paint.gallery.storages[0].max_images}</p>
                                            </span>
                                            <span className='px-4 block'>
                                                <p className="text-1xl font-bold text-black dark:text-white">current images in gallery</p>
                                                <p className="text-1xl font-bold text-black dark:text-gray-400 px-3">{paint.gallery.storages.length}</p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {paint.compose ? <div className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow transform transition-all w-full sm:w-1/2 sm:mt-3">
                                <div className="bg-gray-100 p-5 dark:bg-gray-900">
                                    <div className="sm:flex sm:items-start">

                                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                            <h3 className="text-sm leading-6 font-medium text-gray-400">compose</h3>
                                            {paint.config.interatif.gallery.use ? <div className='flex gap-7'>
                                                <NavLink
                                                    className='bg-yellow-500 h-10 mt-2 flex justify-center items-center rounded-lg text-white w-1/2'
                                                    to={`/paint/${paint._id}/compose`}
                                                >
                                                    create compose
                                                </NavLink>
                                                <NavLink
                                                    className='bg-blue-500 h-10 mt-2 flex justify-center items-center rounded-lg text-white w-1/2'
                                                    to={`/gallery/${paint._id}/compose`}
                                                >
                                                    compose gallery
                                                </NavLink>
                                            </div> : <>
                                                {paint.compose.image ?
                                                    <div className='w-full flex justify-center items-center h-full'>
                                                        <img src={`${config.api_server_uri}${paint.compose.image}`} alt="" />
                                                    </div>
                                                    : null}
                                                <NavLink
                                                    className='bg-yellow-500 h-10 mt-2 flex justify-center items-center rounded-lg text-white'
                                                    to={`/paint/${paint._id}/compose`}
                                                >
                                                    {paint.compose.image ? "Recreate final compose" : "compose your backdrop"}
                                                </NavLink>
                                            </>}

                                        </div>
                                    </div>
                                </div>
                            </div> : null}
                            {paint.config.questions ? <div className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow transform transition-all w-full sm:w-1/2 sm:mt-3">
                                <div className="bg-gray-100 p-5 dark:bg-gray-900">
                                    <div className="sm:flex sm:items-start">
                                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                            <h3 className="text-sm leading-6 font-medium text-gray-400">questions</h3>
                                            {paint.config.questions.map((question) => <div>
                                                <p className="text-1xl font-bold text-black dark:text-white">{question.label}</p>
                                                <p className="text-1xl text-black dark:text-gray-400 px-2">{question.value}</p>
                                            </div>)}
                                        </div>
                                    </div>
                                </div>
                            </div> : null}
                        </div>

                        <div className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow transform transition-all w-full sm:w-1/2 sm:mt-3 ">
                            <div className="bg-gray-100 p-5 dark:bg-gray-900">
                                <div className="sm:flex sm:items-start">
                                    <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 className="text-sm leading-6 font-medium text-gray-400">created</h3>
                                        <p className="text-3xl font-bold text-black dark:text-white">{rtf.format(Math.round((new Date(paint.create_at).getTime() - currentTime) / (1000 * 60 * 60 * 24)), "day")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow transform transition-all w-full sm:w-1/2 sm:mt-3 ">
                            <div className="bg-gray-100 p-5 dark:bg-gray-900">
                                <div className="sm:flex sm:items-start">
                                    <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                        <h3 className="text-sm leading-6 font-medium text-gray-400">end date</h3>
                                        <p className="text-3xl font-bold text-black dark:text-white">{rtf.format(Math.round((new Date(paint.expiry_date).getTime() - currentTime) / (1000 * 60 * 60 * 24)), "day")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(paint.statue === "require_payment") ?
                            <div className='flex items-center justify-center flex-col gap-4 w-full mt-3'>
                                <p>
                                    you need to pay
                                </p>
                                <NavLink
                                    to={`/dashboard/paint/pay/${paint._id}`}
                                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 w-40 text-center"
                                >
                                    Pay
                                </NavLink>
                            </div>
                            : null
                        }

                    </div>
                </div>
                :
                <NotFound />
            }
        </div>
    );
};

export default Paint;

export const loader: LoaderFunction = async ({ params }) => {

    const isLog = await isLogin();
    if (!isLog) return redirect(`/login?paintId=${params.id}`);
    const { user } = isLog;
    if (user) {
        const paint = user.paints.find((paint) => paint._id === params.id);
        if (paint) {
            return {
                paint,
                user,
            };
        } else {
            return null;
        }
    }
    return null;
};