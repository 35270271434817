import axios from "axios";
import config from "../../config";
import { LoaderFunction, useLoaderData, redirect, useParams } from "react-router-dom"
import { useEffect, useState, useMemo } from "react";
import NotFound from "../NotFound";
import { io, Socket } from "socket.io-client"
import { LoadFont } from "../../utils/loadFont";
import Message from "../../components/message";
import MessageColor from "../../config/colors";
import '../../style/gallery/index.css'

const Gallery = () => {
    const gallery = useLoaderData() as ({
        paint: { name: string, config: Config }, storages: {
            images: {
                filepath: string;
                name: string,
                date: string,
            }[], label: string, _id: string
        }[]
    } | undefined);
    const [storages, setStorage] = useState<{
        images: {
            filepath: string;
            name: string,
            date: string,
        }[];
        label: string;
        _id: string
    }[] | undefined>(gallery?.storages);
    const { paintId } = useParams();
    const [imageLine, setimageLine] = useState(gallery?.paint.config.gallery.image_line.count || 3);
    const [message, setMessage] = useState<{ color: string, content: string } | null>(null);
    const dateFormateur = Intl.DateTimeFormat("en-US", { dateStyle: "medium", timeStyle: "short" })

    const socket = useMemo<Socket>(() => io(config.socket_server_uri, {
        query: {
            event: true,
            paintId
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);
    useEffect(() => {
        if (gallery && gallery.paint.config) {
            try {
                LoadFont(config.font);

            } catch (err) {
                if (err instanceof Error) {
                    setMessage({ color: MessageColor.error, content: err.message });
                }
            }
        }
    }, [gallery]);
    useEffect(() => {
        const urlsearchparams = new URLSearchParams(window.location.search);
        if (gallery) {

            document.title = `${gallery.paint.config.title.gallery.replace(/{{name}}/, gallery.paint.name)}`;
        }
        if (urlsearchparams.has("imageLine")) {
            const imageLinequery = Number(urlsearchparams.get("imageLine"));

            if (imageLinequery) {
                setimageLine(imageLinequery);
            }
        }

    }, [gallery]);
    useEffect(() => {
        socket.connect();
        socket.off('gallery');

        socket.on('gallery', (newImage: { image: string, labelId: string, name: string, date: string }) => {
            console.log(newImage);

            setStorage((storage) => {
                if (storage) {
                    const labelIndex = storage.findIndex((label) => label._id === newImage.labelId);
                    if (labelIndex === -1) return undefined;
                    storage[labelIndex].images.push({ filepath: newImage.image, date: newImage.date, name: newImage.name });
                    setTimeout(() => {
                        window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
                    }, 100)
                    return [...storage];

                }
                return undefined;
            });
        });
        socket.on('reload', () => window.location.reload());
        socket.on('admin-message', (message: string) => {

        });
        return () => {
            socket.off('gallery');
            socket.disconnect();
        }
    }, [socket])
    const scrollTo = (element: Element | null) => {
        if (element) {
            setTimeout(() => {
                element.scrollIntoView({ block: "end", behavior: "smooth", })
            }, 100)
        }
    }

    return (
        <div className="w-screen overflow-x-hidden gallery">
            {message ? <Message content={message.content} setMessage={setMessage} color={message.color} /> : null}

            {
                gallery ?
                    <div className="dark:bg-gray-800 dark:text-white relative w-screen h-screen">
                        <div className="w-screen h-16 flex justify-center items-center">
                            <h2 className="text-3xl">
                                {
                                    gallery.paint.name
                                }
                            </h2>
                        </div>

                        {
                            storages ?
                                <div className="flex items-center justify-center h-auto dark:bg-gray-800 flex-col" ref={scrollTo}>
                                    {storages.map((storage, index) => {
                                        return <div className="flex justify-center items-center flex-col my-10" key={index}>
                                            {storage.images.length > 0 ?
                                                <>
                                                    <h2 className="text-2xl w-full h-12 text-center">{storage.label === "default" ? null : storage.label}</h2>
                                                    <div className="grid grid-cols-4 items-center gap-1  relative" style={{ gridTemplateColumns: `repeat(${imageLine}, minmax(0, 1fr)` }}>
                                                        {
                                                            storage.images.map((image, index) => {
                                                                return <div
                                                                    className=" w-full flex items-center justify-center relative cursor-pointer"
                                                                    key={index}>
                                                                    {gallery.paint.config.gallery.show.date || gallery.paint.config.gallery.show.name ? <div className="flex absolute bottom-0 w-full h-16  text-white justify-center items-center flex-col text-4xl ">
                                                                        {gallery.paint.config.gallery.show.name ?
                                                                            <div>
                                                                                {image.name || "'unknown'"}
                                                                            </div>
                                                                            : null}
                                                                        {gallery.paint.config.gallery.show.date ? <div>
                                                                            {image.date ? dateFormateur.format(new Date(image.date)) : "'no date'"}
                                                                        </div> : null}

                                                                    </div> : null}
                                                                    <img
                                                                        src={`${config.api_server_uri}${image.filepath}`}
                                                                        className="w-full"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </> : null}
                                        </div>
                                    })}
                                </div>
                                :
                                <div className="w-full h-1/2 flex justify-center items-center opacity-60">gallery empty</div>
                        }
                    </div>
                    : <NotFound />
            }
        </div>
    );
};

export default Gallery;
export const loader: LoaderFunction = async ({ params }) => {
    try {
        const res = await axios.post(`${config.api_server_uri}/paint/gallery/${params.paintId}`);
        if (res.status === 200) {
            return res.data;
        } else {
            return redirect('/gallery')
        }
    } catch (err) {
        return redirect('/gallery')
    }
};
