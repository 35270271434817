export const LoadFont = async (fontUri: string) => {
    try {
        const font = new FontFace('myartboxfont', `url(${fontUri})`);
        document.fonts.add(font);
        await font.load();

    } catch (err) {
        throw err;
    }

}