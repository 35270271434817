import React, { useState } from 'react';

const GetName = (
    props: {
        handle: (name: string) => void,
        max: number,
        min: number,
    }
) => {

    const { handle } = props;
    const [error, setError] = useState<null | string>(null)
    const [name, setName] = useState("");

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        if (name === "") return setError('name is too shorts')
        event.preventDefault();
        handle(name)
    }
    return (
        <div className='cursor-default'>

            <form onSubmit={handleSubmit} className='w-screen h-screen bg-white/90 dark:bg-gray-800 z-40 absolute flex items-center justify-center flex-col'>
                {error ? <div className='w-full absolute top-3 flex justify-center items-center h-1/2 pointer-events-none'>
                    <div className="w-80 relative">
                        <div className="bg-red-100 border border-red-400 text-red-700 px-10 py-3 rounded mb-8 " role="alert">
                            <span className="block sm:inline">{error}</span>
                            <span className="absolute top-0 bottom-0 right-0 px-4 py-3 pointer-events-auto" onClick={() => setError(null)}>
                                <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                            </span>
                        </div>
                    </div>
                </div> :
                    null}
                <div className="p-12">
                    <input
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="enter your name"
                        required
                        onChange={(event) => setName(event.target.value)}
                        value={name}
                    />
                </div>
                <button
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                    submit
                </button>
            </form>
        </div>
    );
};

export default GetName;