export const fonts: { name: string, uri: string }[] = [
    {
        name: "Helvetica Neue",
        uri: "./font/Helvetica-Neue/Helvetica-Neue66.ttf"
    },
    {
        name: "Fira Mono",
        uri: "https://fonts.gstatic.com/s/firamono/v14/N0bS2SlFPv1weGeLZDtondvHk_fUWZA.woff2"
    },
    {
        name: "Default",
        uri: "none"
    },
]