import { LoaderFunction, NavLink, useLoaderData } from 'react-router-dom';
import NavBar from '../../components/navbar';
import isLogin from '../../utils/isLogin';

const Home = () => {
    const data = useLoaderData() as { user: User };
    const user = data?.user;
    return (
        <div className='h-screen w-full bg-gray-300 dark:bg-gray-900 text-black dark:text-white overflow-x-hidden'>
            <NavBar user={user} />
            <h3 className='text-center text-3xl py-10'>
                WELCOME TO DIGITAL-MURAL
            </h3>
            <NavLink to="/plans" className="text-center text-2xl block font-bold">see plans</NavLink>
            <div className="w-full flex justify-center items-center ">
                <a
                    href="https://digital-mural.com/digital-activity-contact/"
                    className="text-center text-2xl font-bold mt-5 w-auto p-5 h-14 bg-yellow-500 flex justify-center items-center rounded-xl"
                >
                    contact
                </a>
            </div>
            <div className="w-full flex justify-center items-center mt-5 bg-gray-300 dark:bg-gray-900 text-black dark:text-white">
                <p className='w-1/2'>
                    Digital Mural is a unique platform that offers an innovative way to create (digital) ART by collaborating remotely with a professional artist: aNa.
                    At the end, you showcase your creativity and share it with the world.
                    <span className='py-2 block'>
                        Whether you are a single person, a couple, a family or a corporate team, Digital Mural is the perfect solution to set up your artwork and connect with your contacts.
                    </span>
                    <span className='py-2 block'>
                        Digital Mural provides digital tools to draw online. But we also share with you programs and protocoles. Each one gives you the opportunity to reach your goal. You can for instance:
                    </span>
                    <li className='list'>Create an artwork to print and exhibit it</li>
                    <li>Imagine you personal NFT to share on screen</li>
                    <li>Engage remote or nomad teammates into a perfect team building</li>
                    <li>Involve long distance family into a creative program to create a very special gift for birthday, wedding, Christmas present…</li>

                    <span className='py-2 block'>
                        Our mission is to provide a space where creativity can flourish and be appreciated by others. We believe that everyone has a story to tell and a message to share, and our goal is to make it easier for you to do so.  This is why every program starts by a question that everyone have to answer by a drawing. We do not need skills but sincerity!
                        You will next add every sketches into a dedicated personal gallery. Ana artist will use all that creative and collaborative material to create a very unique remote artwork.
                    </span>

                    <span className='py-2 block'>
                        Plus, Our platform is user-friendly and easy to navigate, so you can focus on creating and sharing your content.
                    </span>


                    <span className='py-2 block'>
                        We offer the perfect set up of tools and features you need to help you bring your ideas to life, within 30 days.
                    </span>

                    <span className='py-2 block'>
                        With Digital Mural, you now have the opportunity to connect with everyone from your family or team.
                        By setting the rights questions, you can get essential informations and personal feed back.
                        It can help as a manager. But it also insure to stay in touch with your folks.
                        In both cases, you create more than a remote artwork.
                    </span>

                    <span className='py-2 block'>
                        You build collective memories.
                        And you initiate an interesting positive and creative relationship.
                        No need to travel to the museum. Digital Mural now brings it to you. Because the next step would be to print and offer real artworks edited in a limited number from you personal collaborative Digital Mural done with aNa artist.
                    </span>


                </p>
            </div>

        </div>
    );
};

export default Home;
export const loader: LoaderFunction = async ({ params }) => {
    const isLog = await isLogin();
    return isLog;
};