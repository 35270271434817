import { useState, useEffect, useRef } from "react"
const UseDrapAndDrop = (drop: (element: HTMLImageElement, position: { x: number, y: number }, target: Element) => void, svgImageSize: Size) => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const imageElement = useRef<HTMLImageElement[]>([])
    const currentSelection = useRef<{ element: HTMLImageElement, firstPosition: { position: Position, size: Size } } | null>(null);

    const mousemove = (event: MouseEvent | TouchEvent) => {
        if (event instanceof TouchEvent) {
            const clientX = event.changedTouches[0].clientX;
            const clientY = event.changedTouches[0].clientY;
            setPosition({
                x: clientX,
                y: clientY
            })
        } else {
            setPosition({
                x: event.clientX,
                y: event.clientY
            })
        }
    };
    const mouseup = (event: MouseEvent | TouchEvent) => {
        if (currentSelection.current) {
            if (event instanceof TouchEvent) {
                const clientX = event.changedTouches[0].clientX;
                const clientY = event.changedTouches[0].clientY;

                const element = currentSelection.current.element;

                const firstPosition = currentSelection.current.firstPosition;

                currentSelection.current = null;
                setPosition({ x: clientX, y: clientY });

                const target = document.elementFromPoint(clientX, clientY);
                if (target instanceof Element) {
                    const targetInfo = target.getBoundingClientRect();
                    const scaleX = (firstPosition.position.x * svgImageSize.width / firstPosition.size.width);
                    const scaleY = (firstPosition.position.y * svgImageSize.height / firstPosition.size.height);

                    drop(element, {
                        x: (clientX - targetInfo.x) - scaleX,
                        y: (clientY - targetInfo.y) - scaleY
                    }, target)
                }

            } else {
                const target = event.target;
                if (target instanceof Element) {
                    const scaleX = (currentSelection.current.firstPosition.position.x * svgImageSize.width / currentSelection.current.firstPosition.size.width);
                    const scaleY = (currentSelection.current.firstPosition.position.y * svgImageSize.height / currentSelection.current.firstPosition.size.height);

                    drop(currentSelection.current.element, {
                        x: event.offsetX - scaleX,
                        y: event.offsetY - scaleY
                    }, target)
                }
                currentSelection.current = null;
            }
        }
    };

    const setListener: (element: HTMLImageElement) => void = (element) => {
        if (imageElement.current.some((image) => image === element)) return;
        const mousedown = (event: MouseEvent | TouchEvent) => {
            const targetDomRect = element.getBoundingClientRect();

            if (event instanceof TouchEvent) {

                const clientX = event.changedTouches[0].clientX;
                const clientY = event.changedTouches[0].clientY;
                currentSelection.current = {
                    element,
                    firstPosition: {
                        position: {
                            x: clientX - targetDomRect.x,
                            y: clientY - targetDomRect.y
                        },
                        size: {
                            width: targetDomRect.width,
                            height: targetDomRect.height
                        }
                    }
                }

            } else {

                currentSelection.current = {
                    element,
                    firstPosition: {
                        position: {
                            x: event.offsetX,
                            y: event.offsetY
                        },
                        size: {
                            width: targetDomRect.width,
                            height: targetDomRect.height
                        }
                    }
                }
            }
        }
        element.addEventListener('mousedown', mousedown);
        element.addEventListener('touchstart', mousedown);
        imageElement.current.push(element);
    };
    useEffect(() => {
        // mouse move and touch mouse event
        window.addEventListener('mousemove', mousemove)
        window.addEventListener('touchmove', mousemove)

        // mouse up and touch end event 
        window.addEventListener('mouseup', mouseup)
        window.addEventListener('touchend', mouseup)
        return () => {
            // mouse move and touch mouse event remover
            window.removeEventListener('mousemove', mousemove);
            window.removeEventListener('touchmove', mousemove)

            // mouse up and touch end event remover
            window.removeEventListener('mouseup', mouseup)
            window.removeEventListener('touchend', mouseup)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const drapElement = <div
        className="w-screen h-screen absolute top-0 left-0 pointer-events-none z-50"
        style={{
            touchAction: "none",
            WebkitOverflowScrolling: "touch",
            scrollBehavior: "smooth",
        }}
    >
        {currentSelection.current ? <>
            <img
                style={{
                    top: `${position.y - (currentSelection.current.firstPosition.position.y * svgImageSize.height / currentSelection.current.firstPosition.size.height)}px`,
                    left: `${position.x - currentSelection.current.firstPosition.position.x * svgImageSize.width / currentSelection.current.firstPosition.size.width}px`,
                    width: `${svgImageSize.width}px`,
                    height: `${svgImageSize.height}px`,
                    opacity: "0.5"
                }}
                className="absolute"
                src={currentSelection.current.element.src}
                alt=""
            />
        </> : null}
    </div>

    return {
        setListener,
        drapElement
    }
};

export default UseDrapAndDrop;