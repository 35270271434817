import React from 'react';
import NavBar from '../../components/navbar';
import { LoaderFunction, useLoaderData } from 'react-router-dom';
import isLogin from '../../utils/isLogin';

const About = () => {
    const data = useLoaderData() as ({ user: User } | null);
    return (
        <div className='w-full h-full bg-gray-300 border-gray-200 rounded dark:bg-gray-900'>
            {(data && data.user) ? <NavBar user={data.user} /> : null}
            <div className="w-full flex items-center justify-center mt-9 flex-col gap-8">
                <h1 className='dark:text-white text-3xl underline'>About Digital Mural</h1>
                <p className='dark:text-white w-1/2'>
                    Digital Mural is a platform that offers a unique and innovative way to showcase your creativity and share it with the world. Whether you are an artist, photographer, designer or just someone with a passion for creativity, Digital Mural provides the perfect platform to showcase your work and connect with like-minded individuals.
                </p>
                <p className='dark:text-white w-1/2'>
                    Our mission is to provide a space where creativity can flourish and be appreciated by others. We believe that everyone has a story to tell and a message to share, and our goal is to make it easier for you to do so. Our platform is user-friendly and easy to navigate, so you can focus on creating and sharing your content.
                </p>
                <p className='dark:text-white w-1/2'>
                    We offer a wide range of tools and features to help you bring your ideas to life, including customizable portfolios, collaborative projects, and a supportive community. With Digital Mural, you have the opportunity to connect with other creatives from around the world, share your work, and receive feedback to help you grow and develop your skills.
                </p>
                <p className='dark:text-white w-1/2'>
                    Whether you are looking for inspiration, collaboration or just a place to showcase your work, Digital Mural is the perfect platform for you. Join us today and start sharing your creativity with the world.
                </p>
            </div>
        </div>
    );
};

export default About;
export const loader: LoaderFunction = async ({ params }) => {
    const isLog = await isLogin();
    return isLog;
};