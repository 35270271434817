import { AxiosError } from "axios";
import { toast } from "sonner";

export class Errors {
    static handler(error: unknown) {
        if (error instanceof AxiosError) {
            const data = error.response?.data;
            if (data) toast.error(data.message);
            else toast.error(error.message);
        } else if (error instanceof Error) toast.error(error.message);
        else toast.error("unkown error");
    }
}