import { useEffect, useState } from "react";
import _config from "../../config";
import { TSVG } from "../../@types/svg";
import axios from "axios";
import { toast } from "sonner";
import { Errors } from "../../utils/errors";
import { createPortal } from "react-dom";

export const ImageList = (props: { config: Config; onChange: (config: Config) => void }) => {
    const { config, onChange } = props;
    const [showList, setShowList] = useState<boolean>(false);
    const [currentImageSelect, setCurrentImageSelect] = useState(0);
    const [currentSvgSelect, setcurrentSvgSelect] = useState(0);
    const [currentSection, setCurrentSection] = useState<"svg" | "image">("image");
    const [svgs, setSvg] = useState<TSVG[]>([]);
    useEffect(() => {
        const loadSvg = async () => {
            try {
                const responce = await axios.get(`${_config.api_server_uri}/svg`);
                if (responce.status === 200) {
                    setSvg(responce.data.svgs);
                } else {
                    toast.error(responce.data.message, { duration: 3000 });
                }
            } catch (error) {
                Errors.handler(error);
            }
        };
        loadSvg();
    }, []);

    if (!showList)
        return (
            <div className="w-full">
                <button
                    onClick={() => {
                        setShowList((showList) => !showList);
                        window.scrollTo({
                            behavior: "smooth",
                            left: 0,
                            top: 0,
                        });
                    }}
                    type="button"
                    className="flex text-white  focus:outline-none focus:ring-4 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
                >
                    config images
                </button>
            </div>
        );

    return createPortal(
        <div className={`w-full h-auto absolute top-0 left-0 bg-gray-800 flex justify-center items-center pt-20 z-40`}>
            <div className="absolute top-[50px] right-[50px] w-[100px] h-[100px] ">
                <div
                    className="flex justify-center items-center h-[80px] w-[80px]  p-2.5 transition-all duration-300 text-white rounded-xl bg-gray-800 hover:bg-gray-700 cursor-pointer"
                    onClick={() => setShowList(false)}
                >
                    <svg className="h-10 w-10" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </div>
            </div>
            <div className="flex w-full px-5 lg:px-28">
                <div className="w-[30%] h-screen flex justify-start top-10 items-center text-white px-2 md:px-10 sticky flex-col min-w-[200px]">
                    {currentSection === "image" && config.image_paths[currentImageSelect] ? (
                        <>
                            <img src={`${_config.api_server_uri}/svg/${config.image_paths[currentImageSelect].id}`} alt="" />
                            <span className="py-3">{config.image_paths[currentImageSelect].name}</span>
                        </>
                    ) : null}
                    {currentSection === "svg" && svgs[currentSvgSelect] ? (
                        <>
                            <img src={`${_config.api_server_uri}/svg/${svgs[currentSvgSelect]._id}`} alt="" />
                            <span className="py-3">{svgs[currentSvgSelect].name}</span>
                        </>
                    ) : null}
                </div>
                <div className="w-[70%] pt-8">
                    <div>
                        {config.image_paths.map((svg, index) => {
                            return (
                                <div draggable="true" className="flex justify-center items-center w-full my-2 text-white" key={index}>
                                    <div
                                        className={`w-full cursor-pointer h-14 bg-gray-700 ${
                                            index === currentImageSelect ? "bg-gray-600" : "hover:bg-gray-600"
                                        } rounded-lg grid grid-cols-2  place-items-center`}
                                        onMouseEnter={() => {
                                            setCurrentImageSelect(index);
                                            setCurrentSection("image");
                                        }}
                                    >
                                        <div>{svg.name}</div>
                                        <div className="flex justify-end items-center w-full pr-2">
                                            <button
                                                className={`mx-5 text-center w-full h-10 flex justify-start items-center text-[0.6rem] md:text-sm  my-1 cursor-pointer rounded-lg  px-4 py-2 ${
                                                    config.default_image_select === index ? "bg-blue-700/60" : "hover:bg-blue-600 bg-blue-700"
                                                }`}
                                                type="button"
                                                onClick={() => {
                                                    onChange({ ...config, default_image_select: index });
                                                }}
                                            >
                                                <svg className="w-5 h-5 mr-5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 21">
                                                    <path
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="m6.072 10.072 2 2 6-4m3.586 4.314.9-.9a2 2 0 0 0 0-2.828l-.9-.9a2 2 0 0 1-.586-1.414V5.072a2 2 0 0 0-2-2H13.8a2 2 0 0 1-1.414-.586l-.9-.9a2 2 0 0 0-2.828 0l-.9.9a2 2 0 0 1-1.414.586H5.072a2 2 0 0 0-2 2v1.272a2 2 0 0 1-.586 1.414l-.9.9a2 2 0 0 0 0 2.828l.9.9a2 2 0 0 1 .586 1.414v1.272a2 2 0 0 0 2 2h1.272a2 2 0 0 1 1.414.586l.9.9a2 2 0 0 0 2.828 0l.9-.9a2 2 0 0 1 1.414-.586h1.272a2 2 0 0 0 2-2V13.8a2 2 0 0 1 .586-1.414Z"
                                                    />
                                                </svg>
                                                select as default
                                            </button>

                                            <div
                                                className={`w-14 h-10 flex justify-center items-center cursor-pointer rounded-lg bg-red-600 hover:bg-red-700`}
                                                onClick={() => {
                                                    const imageindex = config.image_paths.findIndex((image) => image.id === svg.id);
                                                    const image_paths = [...config.image_paths];
                                                    image_paths.splice(imageindex, 1);
                                                    console.log(image_paths);

                                                    setCurrentImageSelect(0);
                                                    onChange({ ...config, image_paths });
                                                }}
                                            >
                                                <div className="w-full  flex justify-center items-center">
                                                    <svg
                                                        className={`w-5 h-5 text-gray-800 dark:text-white transition-transform rotate-45 `}
                                                        aria-hidden="true"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 18 18"
                                                    >
                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="w-full h-[1px] rounded-sm bg-white mt-5"></div>
                    <div className="mt-5">
                        {svgs.map((svg, index) => {
                            const isUse = !!config.image_paths.find((image) => image.id === svg._id);
                            if (isUse) return null;
                            return (
                                <div className="flex justify-center items-center w-full my-2 text-white" key={index}>
                                    <div
                                        className={`w-full cursor-pointer h-14 bg-gray-700 ${
                                            index === currentSvgSelect ? "bg-gray-600" : "hover:bg-gray-600"
                                        } rounded-lg grid grid-cols-2 place-items-center`}
                                        onMouseEnter={() => {
                                            setcurrentSvgSelect(index);
                                            setCurrentSection("svg");
                                        }}
                                    >
                                        <div className="w-full flex justify-start px-5">{svg.name}</div>
                                        <div className="flex justify-end w-full pr-2">
                                            <div
                                                className={`w-10 h-10 flex justify-center items-center cursor-pointer rounded-lg ml-3 bg-blue-600 hover:bg-blue-700`}
                                                onClick={() => {
                                                    const image_paths = [...config.image_paths];
                                                    image_paths.push({
                                                        id: svg._id,
                                                        name: svg.name,
                                                    });
                                                    onChange({ ...config, image_paths });
                                                }}
                                            >
                                                <div className="w-full flex justify-center items-center">
                                                    <svg
                                                        className={`w-5 h-5 text-gray-800 dark:text-white transition-transform  `}
                                                        aria-hidden="true"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 18 18"
                                                    >
                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
};
