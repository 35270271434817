import { useEffect, useMemo, useState } from 'react';
import { useParams } from "react-router-dom"
import Gallery from '../../@types/gallery';
import globalConfig from "../../config"
import axios, { AxiosError } from 'axios';
import Message from '../../components/message';
import MessageColor from '../../config/colors';
import { io, Socket } from "socket.io-client"
import { LoadFont } from '../../utils/loadFont';

const Review = () => {
    const { paintId } = useParams();
    interface storagesReiew {
        label: {
            name: string,
            id: string,
        },
        image: {
            filepath: string
            status: "active" | "need-review" | "banned"
            id: string,
            name: string | null,
            date: Date,
        },
    }
    const [storages, setStorages] = useState<storagesReiew[]>([]);
    const [config, setConfig] = useState<Config | null>(null)
    const [message, setMessage] = useState<{ color: string, content: string } | null>(null);
    const socket = useMemo<Socket>(() => io(globalConfig.socket_server_uri, {
        query: {
            event: true,
            paintId
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

    const sendData = async (state: boolean, labelId: string, imageID: string) => {
        try {
            const responce = await axios.post(`${globalConfig.api_server_uri}/paint/gallery/get-review-images/${paintId}/${labelId}/${imageID}`, { state })
            if (responce.status === 200) {
                interface TDate {
                    message: string
                }
                const data: TDate = responce.data;
                setStorages((storages) => {
                    storages.shift();
                    return storages;
                });
                setMessage({ color: MessageColor.success, content: data.message });
            }
        } catch (error) {
            if (error instanceof AxiosError) {
                const message = error.response?.data.message;
                setMessage({ color: MessageColor.error, content: message || "error to get image form server" })
            } else if (error instanceof Error) {
                setMessage({ color: MessageColor.error, content: error.message })
            }
        }
    }
    useEffect(() => {
        const getImage = async () => {
            try {
                const responce = await axios.get(`${globalConfig.api_server_uri}/paint/gallery/get-review-images/${paintId}`)
                if (responce.status === 200) {
                    interface TDate {
                        paint: {
                            name: string,
                            config: Config
                        },
                        storages: Gallery['storages']
                    }
                    const data: TDate = responce.data;

                    document.title = `review - ${data.paint.name}`;

                    const storagesArray: storagesReiew[] = [];
                    for (let i = 0; i < data.storages.length; i++) {
                        const label = data.storages[i];
                        const images = label.images;
                        for (let j = 0; j < images.length; j++) {
                            const image = images[j];
                            storagesArray.push({
                                image: image,
                                label: {
                                    id: label._id!,
                                    name: label.label
                                }
                            });
                        }
                    }
                    setStorages(storagesArray);
                    setConfig(data.paint.config);
                } else {
                    setMessage({ color: MessageColor.error, content: responce.data.message || "error to get image form server" })
                }
            } catch (error) {
                if (error instanceof AxiosError) {
                    const message = error.response?.data.message;
                    setMessage({ color: MessageColor.error, content: message || "error to get image form server" })
                } else if (error instanceof Error) {
                    setMessage({ color: MessageColor.error, content: error.message })
                }
            }
        };
        getImage();
    }, [paintId]);
    useEffect(() => {
        socket.connect();
        socket.on('gallery-review', (newImage: storagesReiew) => {
            setStorages((storages) => {
                return [...storages, newImage];
            });
        });
        return () => {

            console.log("-useEffect");


            socket.off('gallery-review');
            socket.disconnect();
        }
    }, [socket])
    useEffect(() => {
        if (config) {
            try {
                LoadFont(config.font);

            } catch (err) {
                if (err instanceof Error) {
                    setMessage({ color: MessageColor.error, content: err.message });
                }
            }
        }
    }, [config]);
    return (
        <div className='w-full h-full bg-gray-900 flex justify-center items-start flex-col relative'>
            {message ? <Message content={message.content} setMessage={setMessage} color={message.color} /> : null}

            <div className="w-full h-20 flex justify-end items-center">
                <div className="w-60 h-10 text-white flex justify-center items-center">
                    Images count: {storages?.length || 0}
                </div>


            </div>

            <div className="w-full h-full flex justify-center items-center text-white">
                {storages.length > 0 ?
                    <div className="">
                        <img width={500} src={`${globalConfig.api_server_uri}${storages[0].image.filepath}`} alt="" />
                    </div> : <h1>no images</h1>}
            </div>
            {storages.length > 0 && storages[0].image.name ?
                <div className="w-full h-32 flex justify-center items-center text-white">
                    create by {storages[0].image.name}
                </div>
                :
                null
            }
            {storages.length > 0 ?
                <div className="w-full h-96 flex">
                    <div className="w-1/2 h-full  cursor-pointer  flex justify-center items-center">

                        <div className="w-32 h-32 bg-red-700 rounded-3xl" onClick={() => sendData(false, storages[0].label.id, storages[0].image.id)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-full h-full">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 15h2.25m8.024-9.75c.011.05.028.1.052.148.591 1.2.924 2.55.924 3.977a8.96 8.96 0 01-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398C20.613 14.547 19.833 15 19 15h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 00.303-.54m.023-8.25H16.48a4.5 4.5 0 01-1.423-.23l-3.114-1.04a4.5 4.5 0 00-1.423-.23H6.504c-.618 0-1.217.247-1.605.729A11.95 11.95 0 002.25 12c0 .434.023.863.068 1.285C2.427 14.306 3.346 15 4.372 15h3.126c.618 0 .991.724.725 1.282A7.471 7.471 0 007.5 19.5a2.25 2.25 0 002.25 2.25.75.75 0 00.75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 002.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384" />
                            </svg>
                        </div>
                    </div>
                    <div className="w-1/2 h-full cursor-pointer flex justify-center items-center" >
                        <div className="w-32 h-32 bg-blue-700 rounded-3xl" onClick={() => sendData(true, storages[0].label.id, storages[0].image.id)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-full h-full">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
                            </svg>
                        </div>

                    </div>
                </div> : null}
        </div >
    );
};

export default Review;