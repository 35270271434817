import axios from "axios";
import config from "../../config";
import { LoaderFunction, useLoaderData, useParams } from "react-router-dom"
import { useEffect, useState, useMemo } from "react";
import NotFound from "../NotFound";
import { io, Socket } from "socket.io-client"
import { TComposeGallery } from "../../@types/composeGallery";

const ComposeGallery = () => {
    type TData = {
        paint: TPaint;
        gallery: TComposeGallery;
    } | null;

    const compose = useLoaderData() as TData;
    const [storages, setStorage] = useState<{
        filepath: string
    }[] | undefined>(compose?.gallery.images);
    const { paintId } = useParams();
    const [imageLine, setimageLine] = useState(compose?.paint.config.gallery.image_line.count || 3);
    const socket = useMemo<Socket>(() => io(config.socket_server_uri, {
        query: {
            event: true,
            paintId
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

    useEffect(() => {
        const urlsearchparams = new URLSearchParams(window.location.search);
        if (compose) {

            document.title = `${compose.paint.config.title.gallery.replace(/{{name}}/, compose.paint.name)}`;
        }

        if (urlsearchparams.has("imageLine")) {
            const imageLinequery = Number(urlsearchparams.get("imageLine"));

            if (imageLinequery) {
                setimageLine(imageLinequery);
            }
        }

    }, [compose]);
    useEffect(() => {

        socket.connect();
        socket.on('compose', (newImage: { image: string, labelId: string }) => {

            // setStorage((storage) => {
            //     if (storage) {
            //         const label = storage.find((label) => label._id === newImage.labelId);
            //         if (label) {
            //             label.images.push({ filepath: newImage.image });
            //         }

            //         return [...storage];

            //     }
            //     return undefined;
            // });
        });
        return () => {
            socket.off('compose');
            socket.disconnect();
        }
    }, [socket])
    return (
        <div className="w-screen overflow-x-hidden">
            {
                compose ?
                    <div className="dark:bg-gray-800 dark:text-white relative w-screen h-screen">
                        <div className="w-screen h-16 flex justify-center items-center">
                            <h2 className="text-3xl">
                                {
                                    compose.paint.name
                                }
                            </h2>
                        </div>

                        {
                            storages ?
                                <div className="flex items-center justify-center h-auto dark:bg-gray-800 flex-col">
                                    <div className="grid grid-cols-4 items-center gap-1 px-5" style={{ gridTemplateColumns: `repeat(${imageLine}, minmax(0, 1fr)` }} >
                                        {storages.map((storage, index) => {
                                            return <div className="flex justify-center items-center flex-col" key={index}>
                                                <img src={`${config.api_server_uri}${storage.filepath}`} alt="" className="w-full" />
                                            </div>
                                        })}
                                    </div>
                                </div>
                                :
                                <div className="w-full h-1/2 flex justify-center items-center opacity-60">compose empty</div>
                        }
                    </div>
                    : <NotFound />
            }
        </div>
    );
};

export default ComposeGallery;
export const loader: LoaderFunction = async ({ params }) => {
    try {
        const res = await axios.post(`${config.api_server_uri}/paint/compose/gallery/${params.paintId}`);
        if (res.status === 200) {
            return res.data;
        } else {
            return null
        }
    } catch (err) {
        return null

        // return redirect('/compose')
    }
};
