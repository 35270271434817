const loadSVG: LoadSVG = async (uri) => {
    return new Promise(async (resolve) => {
        const res = await fetch(uri);
        const svgString = await res.text();
        if (res.headers.get('Content-Type') === "image/svg+xml") {
            resolve(svgString);
        } else {
            resolve(null);
        }
    });
}
export default loadSVG